import * as React from "react";
import {graphql, PageProps} from "gatsby";

import Layout from "../components/layout";
import Hero from '../components/hero';
import TrustpilotCarousel from "../components/trustpilot/trustpilot-carousel";
import ProductsFilter from "../components/products-filter";
import TemplateHead from "../components/template-head";

let products = require('../data/fnx_products_uk.json');

type DataProps = {
  strapiAdvisor: any,
}

export default function Products({location, data: {strapiAdvisor}}: PageProps<DataProps>) {
  const advisor = strapiAdvisor

  const productCategories = products.object.productMasterData.productCategories
  const allowedCategories = [{id: 1, slug: "flowers"}, {id: 2, slug: "coffins-and-caskets"}, {id: 3, slug: "headstone"}, {id: 4, slug: "urns"}]
  const parentCategories = productCategories.filter((el) => {
    return allowedCategories.some((f) => {
      return f.slug === el.slug && el.parentId === null;
    });
  });

  const childCategories = productCategories.filter((el) => {
    return parentCategories.some((f) => {
      return f.id === el.parentId && el.parentId != null;
    });
  });

  const allProducts = products.object.products

  allProducts.forEach(object => {
    object.parentCategory = parentCategories.filter(function (el) {
      return el.id === object.category.parentId;
    });
  });

  const productChildCat = allProducts.filter((el) => {
    return childCategories.some((f) => {
      return f.id === el.categoryId && el.parentIds.length === 0;
    });
  });

  const productParentCat = allProducts.filter((el) => {
    return parentCategories.some((f) => {
      return f.id === el.categoryId && el.parentIds.length === 0;
    });
  });



  const productList = productChildCat.concat(productParentCat).sort((t1, t2) => {

    const product1 = t1.type === 2 && t1.priceRange !== null  ? Number(t1.priceRange.split("-")[0]) : Number(t1.priceRange);
    const product2 = t2.type === 2 && t2.priceRange !== null ? Number(t2.priceRange.split("-")[0]) : Number(t2.priceRange);
    if (product1 > product2) {
      return 1;
    }
    if (product1 < product2) {
      return -1;
    }
    return 0;
  });

  return (
    <Layout>
      <Hero
        sectionClassName="hero-section hero-section--products pl-4 pr-4"
        bgImageClassName="hero-image hero-image--blog"
        altImages="Fenix Funeral Directors"
        imageGlow={"/images/glow.png"}
        breadcrumb={[{name: 'Fenix Home', slug: '/'}, {name: 'Products', slug: '/products/'}]}
      >
      </Hero>
      <ProductsFilter
        parentCategories={parentCategories}
        childCategories={""}
        productList={productList}
        advisorData={advisor}
        prevLocation={location.state ? location.state : null}
      />
      <TrustpilotCarousel/>
    </Layout>
  )
}

export function Head() {
  return (
    <TemplateHead
      title="Products - Fenix Funeral Directors"
      shortlink="https://fenixfuneral.co.uk/products/"
      alternate="https://fenixfuneral.co.uk/products/"
      canonical="https://fenixfuneral.co.uk/products/"
      og={{
        url: "https://fenixfuneral.co.uk/products/",
        title: "Products - Fenix Funeral Directors",
      }}
      ldjsonbreadcrumb={`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
            "@type": "ListItem",
            "position": "1",
            "name": "Fenix Home","item": "https://fenixfuneral.co.uk/"
            },
            {
            "@type": "ListItem",
            "position": "2",
            "name": "Products","item": "https://fenixfuneral.co.uk/products/"
            }
          ]
        }`
      }
    />
  );
}

export const pageQuery = graphql`
    query {
        strapiAdvisor(url: {eq: "emily-cross"}) {
            id
            title
            url
            fullname
            description {
                data {
                    description
                }
            }
            photo2x {
                localFile {
                    childImageSharp {
                        gatsbyImageData(width: 400, height: 400, placeholder: NONE)
                    }
                }
            }
        }
    }
`;