import * as React from "react";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";

import {Link} from "gatsby";
const cookies = new Cookies();

export default function ProductCard(props: any) {
  const [productPriceRange, setProductPriceRange] = useState("");
  const cookieHub = cookies.get("fenix4hub");

  // console.log(cookieHub)
  //
  // console.log(props.data.priceRange)
  // console.log(cookieHub && props.data.pricePlaces.find(e => e.slug.includes(cookieHub))?.priceRange.split("-"))
  console.log(props?.data.parentCategory[0]?.slug === "headstone")

  useEffect(() => {
    setProductPriceRange(
cookieHub && props.data.pricePlaces.find(e => e.slug.includes(cookieHub)) ?
        props.data.pricePlaces.find(e => e.slug.includes(cookieHub))?.priceRange.split("-") :
        props.data.priceRange !== null && props.data.priceRange.split("-"));
  }, []);

  productPriceRange.length === 2 ? "&#163;" + productPriceRange[0] + " - &#163;" + productPriceRange[1] : productPriceRange

  return (
    <div className={"product-item"}>
      <Link
        to={props.data.parentCategory && props.data.parentCategory.length ? "/products/" + props.data.parentCategory[0]?.slug + "/" + props.data.category?.slug + "/" + props.data.slug + "/" : "/products/" + props.data.category?.slug + "/" + props.data.slug + "/"}
        className="follow-link d-block text-darkblue">
        <div className={"product-item__wrapper d-flex flex-column bg-white"}>
          <div className="product-item__image text-center">
            {props.data.productMedias[0]?.thumbName ?
              <img width={200} height={200} style={{overflow: "visible"}}
                   src={props.data.productMedias[0]?.awsUrl + props.data.productMedias[0]?.thumbName} alt={props.data.name}
                   loading="lazy"/> :
              <img width={200} height={200} style={{overflow: "visible"}} src="/images/default-product.jpg"
                   alt={props.data.name} loading="lazy"/>}
          </div>
          <div className="product-item__content d-flex flex-column justify-content-center align-items-center">{props?.parentCategory?.slug}
            {productPriceRange && <div className="">
              {props?.data.parentCategory[0]?.slug != "headstone" &&
              <h2
                className="text-deepblue product-item__price text-center mb-3">{productPriceRange.length === 2 ? "£" + parseInt(productPriceRange[0]).toLocaleString() + " - £" + parseInt(productPriceRange[1]).toLocaleString() : "£" + parseInt(productPriceRange).toLocaleString()}</h2>}
            </div>}
            {props.data.name && <div className="">
              <p className="text-deepblue product-item__title text-center mb-0">{props.data.name}</p>
              {props?.data.parentCategory[0]?.slug === "headstone" && <h6 className="pt-2 font-italic text-center text-deepblue">For pricing, please contact Fenix</h6>}
            </div>}
          </div>
        </div>
      </Link>
    </div>
  );
};