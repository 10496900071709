import * as React from "react";
import {useEffect, useState} from "react";
import {Link} from "gatsby";

import CtaHero from "./cta-card-hero";
import ProductCard from "./product-card";

export default function ProductsFilter(props: any) {

  const featuredCoffins = [2269, 2350, 2307, 2270, 1941, 2265]
  const featuredFlowers = [2187, 2206, 2193, 2190]

  let allProducts
  if (props.currentParentCategory === 101) {
    props.productList.sort((a, b) => featuredCoffins.indexOf(a.id) - featuredCoffins.indexOf(b.id));

    allProducts = props.productList.filter(item => featuredCoffins.includes(item.id)).concat(props.productList.filter(item => !featuredCoffins.includes(item.id)));

  } else if(props.currentParentCategory === 107) {
    props.productList.sort((a, b) => featuredFlowers.indexOf(a.id) - featuredFlowers.indexOf(b.id));

    allProducts = props.productList.filter(item => featuredFlowers.includes(item.id)).concat(props.productList.filter(item => !featuredFlowers.includes(item.id)));
  } else {
   allProducts = props.productList
  }

  const productParentCategoryData = props.parentCategories

  productParentCategoryData.sort((a) => (a.id === props.currentParentCategory) ? -1 : 1)

  const [products, setProducts] = useState([...allProducts.slice(0, 16)]);
  const [productCategories, setProductCategories] = useState<any>([]);
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(allProducts.length > 16)

  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState(allProducts);

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  const searchItems = (searchValue: any) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = allProducts.filter((item: any) => {
        return item.name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(products);
    }
  };

  useEffect(() => {

    if (loadMore && hasMore) {
      const currentLength = products.length
      const isMore = currentLength < allProducts.length
      const nextResults = isMore
        ? allProducts.slice(currentLength, currentLength + 16)
        : []
      setProducts([...products, ...nextResults])
      setLoadMore(false)
    }
    const isMore = products.length < allProducts.length
    setHasMore(isMore)
    setProductCategories(productParentCategoryData);

  }, [loadMore, hasMore, products])

  function handleChange(e: any) {
    searchItems(e.target.value);
  }

  useEffect(() => {
    props.prevLocation ? window.scrollTo({top: 150, left: 0, behavior: 'smooth'}) : null
  }, [])

  return (
    <div id="filter" className="filter-section filter-section--products pb-5 pl-xl-4 pr-xl-4">
      <div className="filter-wrapper mb-0 pb-xl-5">
        <div
          className={"search-box d-flex flex-column-reverse flex-lg-row align-items-end row no-gutters mb-0 mb-xl-4 pb-0 pb-xl-3 pl-4 pr-4 pl-xl-0 pr-xl-0"}>
          <div className="col-12 col-lg-8 pr-xl-4 pb-xl-0">
            <h1
              className="hero-content__title text-white">{props.pageContextData && props.pageContextData?.name ? props.pageContextData.name : "Products"}</h1>
            <div className="hero-content__subtitle pb-1">
              <div className="pt-1 pb-4 pb-xl-0 mb-0 text-white">When planning a funeral for a loved one, there are often
                choices you need to make.<br/> Here you can find everything you need to create the funeral that's right
                for you.
              </div>
            </div>
          </div>
          {props.advisorData &&
            <div className="col-12 col-lg-4 d-flex flex-column justify-content-end">
              <CtaHero
                advisorData={props.advisorData}
              />
            </div>}
        </div>
        <div className="button-list button-list--primary d-flex justify-content-sm-center mb-4 mb-xl-0">
          <Link to="/products/#filter"
                className={!props.pageContextData || Object.keys(props.pageContextData).length === 0 ? "d-flex align-items-center button-item  justify-content-center button-item--active bg-white text-deepblue text-center" : "d-flex align-items-center button-item justify-content-center button-item--inactive text-white text-center"}
                title="All products">All products</Link>
          {productCategories.map((item: any, index: any) => (
            <Link key={index} to={"/products/" + item.slug + "/"} data-id={item.id} id={item.slug}
                  className={props.pageContextData && props.currentParentCategory === item.id ? "d-flex align-items-center justify-content-center button-item button-item--active bg-white text-deepblue text-center" : "d-flex align-items-center justify-content-center button-item button-item--inactive text-white text-center"}
                  title={item.name}>{item.name}</Link>
          ))}
        </div>
        {props.hasChildCategory && props.hasChildCategory.length > 0 &&
          <div className="button-list button-list--secondary d-flex justify-content-sm-center mb-4 mb-xl-0 mt-4">
            {props.hasChildCategory.map((item: any, index: any) => (
              <Link key={index} to={"/products/" + item.parentCategory[0].slug + "/" + item.slug + "/"}
                    data-id={item.id}
                    className={props.pageContextData && props.pageContextData?.slug === item.slug ? "d-flex align-items-center justify-content-center button-item button-item--active text-white text-center" : "d-flex align-items-center justify-content-center button-item button-item--inactive text-white  text-center"}
                    title={item.name}>{item.name}</Link>
            ))}
          </div>}
        <div className="all-heading pb-4 pt-3 pt-xl-4 pl-4 pr-4 pr-md-0 pl-md-5">
          <h2 className="text-white all-title pt-0 pt-xl-3">All</h2>
          <div className="text-white all-subtitle">Displaying 1-{products.length} of {allProducts.length} results</div>
        </div>
        <div className="search-result product-list d-flex flex-wrap justify-content-start pl-4 pr-4 pl-xl-0 pr-xl-0">
          {searchInput.length > 2 && filteredResults?.length > 0 ? (filteredResults.map((item: any, index: any) => {
              return (
                <ProductCard
                  key={index}
                  data={item}
                />
              );
            })
          ) : searchInput.length > 2 && filteredResults?.length === 0 ? (
            <div className={"col-12 article-col mb-4 d-flex justify-content-center align-items-center"}>
              <h1 className={"text-white"}>Your search for {searchInput} gave 0 hits</h1>
            </div>
          ) : products.map((item: any, index: any) => {

            return (
              <ProductCard
                key={index}
                data={item}
              />
            );
          })
          }
        </div>
        {hasMore && allProducts?.length > 16 && searchInput.length === 0 ? (
          <div className="d-flex justify-content-center mt-4">
            <button
              className={'btn btn--more bg-white text-deepblue d-flex align-items-center justify-content-center text-center'}
              onClick={handleLoadMore}>Load More
            </button>
          </div>) : ('')}
      </div>
    </div>
  );
}